import { createContext, useContext } from 'react'
// import {State} from './AuthState'

export interface iAuthState {
	isInitialized: boolean
	isAuthenticated: boolean
	isLoading: boolean
	authError: string
	user: any
	profile: any
}

interface iAuthMethods {
	login({ name, password }: { name: string; password: string }): void
	logout(): void
	setUserNotAuthenticated(): void
	getProfileFromServer(): void
}

// export interface AuthContextData {
// 	// isLoading: boolean
// 	isAuthenticated: boolean
// 	// loginWindowIsVisible: boolean
// 	user: any
// 	login?({name}: {name: string}): void
// 	logout?(): void
// }

export type AuthContextData = iAuthState & iAuthMethods

export const authContextDefaultValue: AuthContextData = {
	isAuthenticated: false,
	isInitialized: false,
	isLoading: false,
	authError: '',
	user: {},
	profile: {},
	login: () => null,
	logout: () => null,
	setUserNotAuthenticated: () => null,
	getProfileFromServer: () => null,
}

export const AuthContext = createContext<AuthContextData>(
	authContextDefaultValue
)

export function useAuth(): AuthContextData {
	return useContext(AuthContext)
}
