// import {format} from 'date-fns'

const regExDateISO8601 =
	/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|[-+]\d{2}:\d{2})/

export const formatLocalDateISO8601 = (date) => {
	let dif, pad, tzo
	tzo = -date.getTimezoneOffset()
	dif = tzo >= 0 ? '+' : '-'
	pad = function (num) {
		const norm = Math.abs(Math.floor(num))
		return (norm < 10 ? '0' : '') + norm
	}

	return (
		date.getFullYear() +
		'-' +
		pad(date.getMonth() + 1) +
		'-' +
		pad(date.getDate()) +
		'T' +
		pad(date.getHours()) +
		':' +
		pad(date.getMinutes()) +
		':' +
		pad(date.getSeconds()) +
		dif +
		pad(tzo / 60) +
		':' +
		pad(tzo % 60)
	)
	// return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
}

export const toJson = (obj) => {
	return JSON.stringify(obj, (key, value) => {
		if (regExDateISO8601.test(value)) {
			return formatLocalDateISO8601(new Date(value))
		}
		return value
	})
}

export const fromJson = (str) => {
	try {
		return JSON.parse(str, (key, value) => {
			if (regExDateISO8601.test(value)) {
				return new Date(value)
			}
			return value
		})
	} catch (e) {
		console.error(e)
		return null
	}
}
