import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'

import AppBar from './AppBar'
import { pages } from '../pages/Pages'
import {
	Drawer,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@mui/material'

type Props = {
	children?: JSX.Element | JSX.Element[]
}

function Layout({ children }: Props) {
	return (
		<Box sx={{ display: 'flex' }}>
			<AppBar pages={pages} />
			<Box
				component='nav'
				sx={{
					width: { sm: 240 },
					flexShrink: { sm: 0 },
					display: { xs: 'none', sm: 'none', md: 'block' },
				}}
			>
				<Toolbar />
				<List>
					{pages.map(page => (
						<ListItem button key={page.name} component={NavLink} to={page.path}>
							<ListItemIcon>{page.icon}</ListItemIcon>
							<ListItemText primary={page.title} />
						</ListItem>
					))}
				</List>
				
			</Box>
			{/* <Box
				component='main'
				sx={{
					flexGrow: 1,
					height: '100vh',
					overflow: 'hidden',
				}}
			> */}
			{/* <Box
				sx={{
					flexGrow: 1,
					height: '100%',
				}}
			> */}
			<Box
				component='main'
				sx={{
					backgroundColor: theme =>
						theme.palette.mode === 'light'
							? theme.palette.grey[100]
							: theme.palette.grey[900],
					flexGrow: 1,
					height: '100vh',
					overflow: 'auto',
				}}
			>
				<Toolbar />
				<Container>{children ? children : <Outlet />}</Container>
			</Box>
			{/* </Box> */}
			{/* </Box> */}
		</Box>
	)
}

export default Layout
