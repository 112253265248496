import React, { ChangeEventHandler, useRef } from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import { localityRecord } from '@client/app/typings/types'
import { useSelectLocalityModal } from './useSelectLocalityModal'
import { ListItemButton } from '@mui/material'

type Props = {
	visible: boolean
	close: (locality: localityRecord | undefined) => void
	title: string
}

function SelectLocalityModal({ visible, close, title }: Props) {
	const textInputRef = useRef(null)
	const {
		handleSelectLocality,
		handleChangeText,
		foundedLocalities,
		searchString,
	} = useSelectLocalityModal({
		visible,
		close,
		textInputRef,
	})

	function handleClose(event) {
		close(undefined)
	}

	function onTextFieldChange(event) {
		handleChangeText(event.target.value)
	}

	return (
		<Dialog
			open={visible}
			onClose={handleClose}
			scroll='paper'
			aria-labelledby='scroll-dialog-title'
			aria-describedby='scroll-dialog-description'
		>
			{/* <DialogTitle id='scroll-dialog-title'>{title}</DialogTitle> */}
			<DialogContent>
				<TextField
					autoFocus
					margin='dense'
					label={title}
					type='text'
					fullWidth
					variant='standard'
					value={searchString}
					onChange={onTextFieldChange}
					ref={textInputRef}
				/>
				<List>
					{foundedLocalities.map(locality => (
						<ListItem dense key={locality.uid}>
							<ListItemButton onClick={() => handleSelectLocality(locality)}>
								<ListItemText primary={locality.locality} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</DialogContent>
		</Dialog>
	)
}

export default SelectLocalityModal
