import {
	USER_AUTHENTICATED,
	USER_NOT_AUTHENTICATED,
	AUTH_FAIL,
	USER_FOUND,
	USER_NOT_FOUND,
	SET_PROFILE,
	START_AUTHENTICATION,
	APP_IS_INITIALIZED,
	// DELETE_TODO,
	// CLEAR_TODO_TITLE,
} from './authTypes'

export default (state, { type, payload }) => {
	switch (type) {
		case START_AUTHENTICATION:
			return {
				...state,
				isLoading: true,
				authError: '',
			}

		case USER_AUTHENTICATED:
			return {
				...state,
				isLoading: false,
				loginWindowIsVisible: false,
				isAuthenticated: true,
				user: payload.user,
			}

		case USER_NOT_AUTHENTICATED:
			return {
				...state,
				isLoading: false,
				loginWindowIsVisible: true,
				isAuthenticated: false,
				user: {},
			}

		case SET_PROFILE:
			return {
				...state,
				profile: payload.profile,
			}

		case AUTH_FAIL:
			return {
				...state,
				isLoading: false,
				authError: payload.message,
			}
		case APP_IS_INITIALIZED:
			return {
				...state,
				isInitialized: true,
			}

		default:
			return state
	}
}
