import React, {
	PropsWithChildren,
	Provider,
	ReactChildren,
	useCallback,
	useEffect,
	useReducer,
	useState,
} from 'react'
// import Portal from '@burstware/react-native-portal'

// Bring the context
import { AuthContext, AuthContextData } from './AuthContext'

// Bring the reducer
import authReducer from './authReducer'

// Bring the types
import {
	USER_AUTHENTICATED,
	USER_NOT_AUTHENTICATED,
	AUTH_FAIL,
	SET_PROFILE,
	START_AUTHENTICATION,
	APP_IS_INITIALIZED,
	// USER_FOUND,
	// USER_NOT_FOUND,
	// DELETE_TODO,
	// CLEAR_TODO_TITLE,
} from './authTypes'

import {
	auth,
	getLocalData,
	getServerData,
	// initStorage,
	setLocalData,
} from '../../api'
import { ErrorWithStatus } from '../../../typings/types'
// import LoginModal from '../../components/LoginModal'

export interface State {
	isInitialized: boolean
	isAuthenticated: boolean
	authError: string
	isLoading: boolean
	user: Record<string, unknown> // any object
	profile: Record<string, unknown>
	loginWindowIsVisible: boolean
	login?({ name, password }: { name: string; password: string }): Promise<void>
	setUserNotAuthenticated?: () => void
	getProfileFromServer?: () => void
}

const AuthState = ({ children }: any) => {
	// Define our state
	const initialState: State = {
		isInitialized: false,
		isLoading: false,
		isAuthenticated: true,
		authError: '',
		loginWindowIsVisible: false,
		user: {},
		profile: {},
	}

	// Dispatch the reducer
	// This come from useReducer from ReactJS
	const [state, dispatch] = useReducer(authReducer, initialState)

	useEffect(() => {
		checkAuthenticated()
		loadDataFromLocal()
	}, [])

	const loadDataFromLocal = async () => {
		try {
			const _profile = await getLocalData('profile', {})
			dispatch({ type: SET_PROFILE, payload: { profile: _profile } })
		} catch (error) {
			if (error instanceof Error) console.error(error.message)
		}
	}

	const checkAuthenticated = async () => {
		// console.log('init')

		// await initStorage({ user: {}, docs: [] })
		const user = await getLocalData('user')
		if (user && Object.keys(user).length > 0)
			dispatch({ type: USER_AUTHENTICATED, payload: { user } })
		else dispatch({ type: USER_NOT_AUTHENTICATED, payload: {} })
		dispatch({ type: APP_IS_INITIALIZED, payload: {} })
	}

	const setUserNotAuthenticated = useCallback(() => {
		dispatch({ type: USER_NOT_AUTHENTICATED, payload: {} })
	}, [])

	const getProfileFromServer = useCallback(async () => {
		console.log('getProfileFromServer')

		// return
		if (isAuthenticated)
			try {
				const _profile = await getServerData(user, 'profile')

				dispatch({ type: SET_PROFILE, payload: { profile: _profile } })
				// return
				await setLocalData({ profile: _profile })
			} catch (error: any) {
				if (error?.status === 401) setUserNotAuthenticated()
				console.error(error.message, error?.status)
			}
	}, [setUserNotAuthenticated])

	const login = async ({
		name,
		password,
	}: {
		name: string
		password: string
	}) => {
		console.log('login')
		dispatch({ type: START_AUTHENTICATION, payload: null })
		try {
			const { user } = await auth({ name, password })
			dispatch({ type: USER_AUTHENTICATED, payload: { user } })
			setLocalData({ user })
		} catch (err) {
			if (err instanceof Error) {
				dispatch({ type: AUTH_FAIL, payload: { message: err.message } })
				console.error(err.message)
			}
		}
	}

	const logout = async () => {
		try {
			dispatch({ type: USER_NOT_AUTHENTICATED, payload: null })
			setLocalData({ user: null })
			setLocalData({ docs: null })
			setLocalData({ profile: null })
		} catch (err) {
			if (err instanceof Error) {
				dispatch({ type: AUTH_FAIL, payload: err.message })
				console.error(err.message)
			}
		}
	}

	// Destruct the states
	const {
		user,
		profile,
		isLoading,
		isAuthenticated,
		isInitialized,
		authError,
		loginWindowIsVisible,
	} = state
	// const [loginModalIsVisible, setLoginModalVisible] = useState(false)

	// Here's where we gonna use this state and funcitons to dealing with the context
	// The context will wrapping our entire application with this component and accept children in it
	// Anything state or function, must be passed in to value props in this provider in order to use it
	// NOTE: PLEASE NOTICE, IF YOU DIDN'T PASS THE STATE OR THE FUNCTION in THIS VALUE PROPS, YOU WILL GET AN ERROR
	return (
		<AuthContext.Provider
			value={{
				user,
				profile,
				isLoading,
				isAuthenticated,
				isInitialized,
				authError,
				login,
				logout,
				setUserNotAuthenticated,
				getProfileFromServer,

				// getTodos,
				// setTodoTitle,
				// createTodo,
				// deleteTodo,
			}}
		>
			{/* <Portal.Host>
				{children}
				<Portal>
					<LoginModal isVisible={loginWindowIsVisible} login={login} />
				</Portal>
			</Portal.Host> */}
			{children}
		</AuthContext.Provider>
	)
}

export default AuthState
