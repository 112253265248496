// import OrderTracking from '@client/app/src/components/OrderTracking'
import OrderTracking from '../сomponents/OrderTracking'
import { useAuth } from '@client/app/src/context/auth/AuthContext'
import { Button, Container } from '@mui/material'
import React, { useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

function OrderTrackingPage(): JSX.Element {
	const navigate = useNavigate()
	const { ordersList } = useParams()
	const { isInitialized, isAuthenticated, user } = useAuth()

	useEffect(() => {
		if (!isInitialized) return
		// if (!isAuthenticated) navigate('/login')
	}, [isInitialized, isAuthenticated, navigate])

	return (
		<Container
			sx={{
				flexGrow: 1,
				overflow: 'auto',
			}}
		>
			{/* <Text>Профиль</Text>
			<Text>isAuthenticated = {String(isAuthenticated)}</Text>
			<Text>{JSON.stringify(user)}</Text> */}

			{/* <Button onClick={() => logout()}>Выход</Button> */}
			<OrderTracking ordersList={ordersList} />
		</Container>
	)
}

export default OrderTrackingPage
