import { fromJson, toJson } from './services/storage/jsonHelpers'
import { Storage } from './services/storage/localStorage'
import { API_URL } from './settings'

// const API_URL = 'http://192.168.1.108:3000/'

async function getLocalData(
	storageKey: string,
	delaultValue: any | undefined = undefined
) {
	const data = await Storage.getData(storageKey)
	if (data) return data
	else return delaultValue
}

async function setLocalData(data) {
	return await Storage.setData(data)
}

// async function initStorage(initiaValues) {
// 	await Storage.init(initiaValues)
// }

async function getServerData(user, url: string, params?) {
	console.log('get', `${API_URL}/api/${url}`)

	const fullUrl = `${API_URL}/api/${
		url +
		'?' +
		// + (params ? '?' + new URLSearchParams({params}) : '')

		new URLSearchParams({ ...params, terminalUid: user.terminalUid })
	}`
	const response = await fetch(fullUrl)

	if (response.status === 401)
		throwError('unauthentocated mess', response.status)

	// let response = await fetch(`${API_URL}/api/${url}`)
	// console.dir(await response.text())
	// response = await fetch('https://jsonplaceholder.typicode.com/todos')
	// return await response.json()
	console.log('')
	const text = await response.text()
	console.log('')
	return fromJson(text)
}

async function getServerFile(params?: any) {
	const fullUrl = `${API_URL}/api/files?${new URLSearchParams({ ...params })}`
	console.log('get', fullUrl)
	const response = await fetch(fullUrl)

	if (response.status === 401)
		throwError('unauthentocated mess', response.status)

	// let response = await fetch(`${API_URL}/api/${url}`)
	// console.dir(await response.text())
	// response = await fetch('https://jsonplaceholder.typicode.com/todos')
	// return await response.json()

	return await response.blob()
}

function throwError(message: string, status: number) {
	const error: any = new Error(message)
	error.status = status
	throw error
}

async function putServerData(url: string, data) {
	console.log('put', `${API_URL}/api/${url}`)

	const response = await fetch(`${API_URL}/api/${url}`, {
		method: 'PUT',
		body: toJson(data),
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		},
	})
	// console.dir(await response.text())
	// response = await fetch('https://jsonplaceholder.typicode.com/todos')
	// return await response.json()

	return fromJson(await response.text())
}

async function auth({ name, password }) {
	console.log(`${API_URL}/auth`)

	try {
		const response = await fetch(`${API_URL}/auth`, {
			method: 'POST',
			body: JSON.stringify({ name, password }),
			headers: {
				'Content-Type': 'application/json',
			},
		})

		if (!response.ok) {
			const body = await response.json()
			throw new Error(`${API_URL}/auth: ` + body.message)
		}
		return await response.json()
	} catch (error) {
		console.error('Ошибка:', error)
		throw error
	}
}

async function checkUser({ name }) {
	const searchParams = new URLSearchParams({
		name,
	})
	const response = await fetch(`${API_URL}/api/user?${searchParams}`)
	return response.status
}

async function getCompletedDocFromServer({ type, _id, user }) {
	const dataFromServer = await getServerData(user, `docs/completed/doc`, {
		type,
		_id,
	})
	if (!dataFromServer) return
	if (dataFromServer && dataFromServer.redirectTo) return dataFromServer

	return dataFromServer
}

export async function filePatchRequest({ doc, file }) {
	try {
		const response = await fetch(`${API_URL}/api/files`, {
			method: 'PATCH',
			body: JSON.stringify({
				doc: {
					type: doc.type,
					_id: doc._id,
				},
				file,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		})
		console.log('')
		console.log('')
		console.log('')

		const changedDoc = await response.json()
		return changedDoc
	} catch (error) {
		console.error('Ошибка при патче файла:', error)
		throw error
	}
}

export {
	getLocalData,
	setLocalData,
	getServerData,
	putServerData,
	checkUser,
	auth,
	// initStorage,
	getServerFile,
	getCompletedDocFromServer,
}
