import { getServerData } from '@client/app/src/api'
import { localityRecord } from '@client/app/typings/types'
import { TextInput } from 'react-native'
import { useRef, useEffect, useState } from 'react'

export function useSelectLocalityModal({ visible, close, textInputRef }) {
	const [searchString, setSearchString] = useState<string>('')
	const [foundedLocalities, setFoundedLocalities] = useState<localityRecord[]>(
		[]
	)

	useEffect(() => {
		if (visible && textInputRef?.current) textInputRef.current.focus()
	}, [visible])

	function handleSelectLocality(locality: localityRecord) {
		close(locality)
	}

	async function handleChangeText(text: string) {
		setSearchString(text)
		const _foundedLocalities = await getServerData({}, 'addresses', {
			searchStr: text,
		})
		setFoundedLocalities(_foundedLocalities ? _foundedLocalities : [])
	}

	return {
		handleSelectLocality,
		handleChangeText,
		foundedLocalities,
		searchString,
	}
}
