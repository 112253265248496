import React, { useEffect, useState } from 'react'
import { getServerData } from '@client/app/src/api'
import { localityRecord } from '@client/app/typings/types'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import SelectLocalityModal from './SelectLocalityModal'
import { Box, Grid, TextField } from '@mui/material'
import CalculateResults from './CalculateResults'
import { ICalculateResult } from '@client/app/typings/calculate.types'
import { getDialogChangedField } from '@client/app/src/helpers/formsHelpers'
import NumberFormat from 'react-number-format'
import SelectFromList from './SelectFromList'
import SelectFromListControlledSearch from './SelectFromListControlledSearch'

const fieldTypes = {
	weight: 'float',
	volume: 'float',
}

type CalculateObject = {
	volume: number
	weight: number
	addressFrom?: any
	addressTo?: any
}

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void
	name: string
}

function getFilteredLocalities(_localities, filterString) {
	return _localities
		.filter(localityItem => {
			return localityItem.locality
				.toLowerCase()
				.includes(filterString.toLowerCase())
		})
		.map(localityItem => {
			return {
				uid: localityItem.uid,
				label: localityItem.locality,
				secondary: `${localityItem.country} ${localityItem.region} ${localityItem.postIndex}`,
			}
		})
}

const Calculate = ({ localities }) => {
	const [selectFromIsVisible, setSelectFromVisibility] =
		useState<boolean>(false)
	const [selectToIsVisible, setSelectToVisibility] = useState<boolean>(false)
	// const [fromLocality, setFromLocality] = useState<localityRecord>()
	// const [toLocality, setToLocality] = useState<localityRecord>()
	const [results, setResults] = useState<ICalculateResult[]>([])
	const [volume, setVolume] = useState<number>(0)
	const [weight, setWeight] = useState<number>(0)
	const [calculateObj, setCalculateObj] = useState<CalculateObject>({
		volume: 0,
		weight: 0,
		addressFrom: null,
		addressTo: null,
	})
	const [fromLocalities, setFromLocalities] = useState([])
	const [toLocalities, setToLocalities] = useState([])

	useEffect(() => {
		setFilteredFromLocalities()
		setFilteredToLocalities()
	}, [localities])

	function setFilteredFromLocalities(filterString = '') {
		const _fromLocalities = getFilteredLocalities(localities, filterString)
		setFromLocalities(_fromLocalities)
	}

	function setFilteredToLocalities(filterString = '') {
		const _fromLocalities = getFilteredLocalities(localities, filterString)
		setToLocalities(_fromLocalities)
	}

	function handleCloseFromLocaltySelection(
		locality: localityRecord | undefined
	) {
		if (locality)
			setCalculateObj(prevCalculateObj => ({
				...prevCalculateObj,
				addressFrom: locality,
			})) // setFromLocality(locality)
		setSelectFromVisibility(false)
	}

	function handleCloseToLocaltySelection(locality: localityRecord | undefined) {
		if (locality)
			setCalculateObj(prevCalculateObj => ({
				...prevCalculateObj,
				addressTo: locality,
			})) // setToLocality(locality)
		setSelectToVisibility(false)
	}

	const handleCalculateButton = async () => {
		const _results = await getServerData({}, 'calculate', {
			addressFromUid: calculateObj.addressFrom.uid,
			addressToUid: calculateObj.addressTo.uid,
			volumeStr: calculateObj.volume.toString(),
			weightStr: calculateObj.weight.toString(),
		})
		setResults(_results ? _results : [])
	}

	function onFieldChange(event) {
		const { name, value } = event.target
		const type = fieldTypes[name]

		onDialogFieldChange(name, value, type)
	}

	function onDialogFieldChange(name, value, type?: string | undefined) {
		const changed = getDialogChangedField(name, value, type)
		if (changed)
			setCalculateObj(prevCalculateObj => ({ ...prevCalculateObj, ...changed }))
	}

	return (
		<div>
			<Typography variant='h5'>Калькулятор стоимости пересылки</Typography>
			{/* <Button
					variant='outlined'
					onClick={() => setSelectFromVisibility(true)}
				>
					{calculateObj.addressFrom
						? calculateObj.addressFrom.locality
						: 'Город отправления'}
				</Button>
				<Button variant='outlined' onClick={() => setSelectToVisibility(true)}>
					{calculateObj.addressTo
						? calculateObj.addressTo.locality
						: 'Город получения'}
				</Button> */}

			<Grid container>
				<Grid item xs={12} md={6}>
					<SelectFromListControlledSearch
						label='Город отправления'
						items={fromLocalities}
						itemLabelKey='locality'
						selectedValue={calculateObj.addressFrom}
						selectedValueRepresentation={calculateObj.addressFrom?.label}
						setSelectedValue={handleCloseFromLocaltySelection}
						onFilterChange={filterString =>
							setFilteredFromLocalities(filterString)
						}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<SelectFromListControlledSearch
						label='Город получения'
						items={toLocalities}
						itemLabelKey='locality'
						selectedValue={calculateObj.addressTo}
						selectedValueRepresentation={calculateObj.addressTo?.label}
						setSelectedValue={handleCloseToLocaltySelection}
						onFilterChange={filterString =>
							setFilteredToLocalities(filterString)
						}
					/>
				</Grid>
			</Grid>
			{/* sx={{ display: 'flex', justifyContent: 'space-between' }} */}
			<Grid container>
				<Grid item>
					<TextField
						label='Вес, кг.'
						type='number'
						variant='filled'
						name='weight'
						value={calculateObj.weight.toString()}
						onChange={onFieldChange}
						inputProps={{ min: 0 }}
					/>
				</Grid>
				<Grid item>
					<TextField
						label='Объём, м3'
						type='number'
						variant='filled'
						value={calculateObj.volume.toString()}
						name='volume'
						onChange={onFieldChange}
						inputProps={{ min: 0, step: 0.01 }}
					/>
				</Grid>
				{/* <NumberFormat /> */}
				<Button onClick={handleCalculateButton}>рассчитать</Button>
			</Grid>
			<Box>
				<CalculateResults items={results} />
			</Box>
			<SelectLocalityModal
				title='Город отправления'
				visible={selectFromIsVisible}
				close={handleCloseFromLocaltySelection}
			/>
			<SelectLocalityModal
				title='Город получения'
				visible={selectToIsVisible}
				close={handleCloseToLocaltySelection}
			/>
		</div>
	)
}

export default Calculate
