import React from 'react'
import { ListItemIcon } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { ICalculateResult } from '@client/app/typings/calculate.types'

type Props = { item: ICalculateResult }

function FromToIcons({ item }: Props) {
	if (!item.pickupVariant && !item.deliveryVariant) return null
	return (
		<ListItemIcon>
			{item.pickupVariant === 'd' ? <HomeIcon /> : <CorporateFareIcon />}
			<ArrowForwardIcon />
			{item.deliveryVariant === 'd' ? <HomeIcon /> : <CorporateFareIcon />}
		</ListItemIcon>
	)
}

export default FromToIcons
