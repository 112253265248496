// import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import React from 'react'

const SpanWithSpaceAfter = styled('span')`
	&:after {
		content: ' ';
	}
	word-spacing: -0.1em;
	letter-spacing: -0.05em;
	// color: 'gray'
`

// const useStyles = makeStyles({
// 	muted: {
// 		color: 'rgba(0, 0, 0, 0.5)',
// 	},
// 	spaceAfter: {
// 		'&:after': {
// 			content: '" "',
// 		},
// 		wordSpacing: '-0.1em',
// 		letterSpacing: '-0.01em',
// 		// color: 'gray'
// 	},
// })

export default function OrderNumber({ number }): JSX.Element {
	// const classes = useStyles()

	const year = number.substring(0, 4)
	const month = number.substring(4, 6)
	const day = number.substring(6, 8)
	const indexOfSlash = number.indexOf('/')
	const lastSymbol = indexOfSlash > -1 ? indexOfSlash - 1 : number.length
	const numberInDay = number.substring(8, lastSymbol - 3)
	const postfix = number.substring(lastSymbol - 3, lastSymbol)
	const subNumber = indexOfSlash > -1 ? number.substring(indexOfSlash) : ''

	return (
		<span>
			{/* {number} */}
			<SpanWithSpaceAfter>{year}</SpanWithSpaceAfter>
			<SpanWithSpaceAfter>{month}</SpanWithSpaceAfter>
			<SpanWithSpaceAfter>{day}</SpanWithSpaceAfter>
			<SpanWithSpaceAfter>{numberInDay}</SpanWithSpaceAfter>
			<i>{postfix}</i>
			<strong>{subNumber}</strong>
		</span>
	)
}
