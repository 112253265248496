import OrderTrackingInfo from './OrderTrackingInfo'
import { getServerData } from '@client/app/src/api'
import { useAuth } from '@client/app/src/context/auth/AuthContext'
import { IOrderTrackingInfo } from '@client/app/typings/types'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, FC, createRef, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface OrderTrackingParams {
	ordersList?: string
}

const OrderTracking: FC<OrderTrackingParams> = ({ ordersList }) => {
	const [orderNumber, setOrderNumber] = useState<string>('')
	const [prevOrderNumber, setPrevOrderNumber] = useState<string>('')
	const [orderTrackingInfo, setOrderTrackingInfo] = useState<
		IOrderTrackingInfo[]
	>([])

	const { setUserNotAuthenticated } = useAuth()
	const navigate = useNavigate()

	useEffect(() => {
		if (ordersList) getResultsAndSetOrderTrackingInfo()
	}, [ordersList])

	useEffect(() => {
		setOrderNumber(ordersList)
	}, [navigate])

	const getResultsAndSetOrderTrackingInfo = async () => {
		const _results = await getServerData({}, 'tracking', {
			searchFields: ordersList,
		})
		setOrderTrackingInfo(_results ? _results : [])
		setPrevOrderNumber(orderNumber)
	}

	function handleChangeOrderNumber(event: ChangeEvent<HTMLInputElement>) {
		setOrderNumber(event.target.value)
	}

	const handlePressOrderTrackingButton = async () => {
		// try {
		// 	const _results = await getServerData({}, 'tracking', {
		// 		searchFields: orderNumber,
		// 	})
		// 	setOrderTrackingInfo(_results ? _results : [])
		// 	setPrevOrderNumber(orderNumber)
		// } catch (error) {
		// 	if (error?.status === 401) setUserNotAuthenticated()
		// 	console.error(error.message, error?.status)
		// }
		if (orderNumber === ordersList) await getResultsAndSetOrderTrackingInfo()
		else navigate(`/order-tracking/${orderNumber}`)
	}

	return (
		<div>
			<Typography variant='h5'>Отслеживание заказов</Typography>
			<Grid container>
				<Grid style={{ display: 'flex' }} item xs>
					<TextField
						fullWidth
						variant='filled'
						label='Номер заказа или накладной'
						value={orderNumber}
						onChange={handleChangeOrderNumber}
					/>
				</Grid>
				<Grid item alignItems='stretch' style={{ display: 'flex' }} xs>
					<Button onClick={handlePressOrderTrackingButton}>Отследить</Button>
				</Grid>
			</Grid>

			{/* {orderTrackingInfo.length === 0 && prevOrderNumber ? (
				<span>
					по заказу <strong>{prevOrderNumber}</strong> информация не найдена
				</span>
			) : null} */}

			{orderTrackingInfo.length > 0 ? (
				<OrderTrackingInfo infoArray={orderTrackingInfo} />
			) : null}

			{/* {orderTrackingInfo.length > 0 ? (
				<OrdersTrackingTable infoArray={orderTrackingInfo} />
			) : null} */}
		</div>
	)
}

export default OrderTracking
