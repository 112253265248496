import { compress, decompress } from 'lz-string'
import { toJson, fromJson } from './jsonHelpers'
// import { IStorage } from './index'

const COMPRESSED = true
const worker = new Worker('webWorker.js')
worker.onmessage = $event => {
	if ($event && $event.data) {
		// console.log('storage from WORKER', $event.data)
		for (const key in $event.data) {
			if ($event.data.hasOwnProperty(key)) {
				localStorage.setItem(key, $event.data[key])
			}
		}
	}
}

async function getData(key) {
	// return window.localStorage.getItem(keyName)

	return fromJson(
		COMPRESSED
			? decompress(localStorage.getItem(key))
			: localStorage.getItem(key)
	)
}

async function setData(obj) {
	const key: string = Object.keys(obj)[0]
	const value: any = obj[key]

	// return window.localStorage.setItem(keyName, value)
	if (!value) {
		localStorage.removeItem(key)
	} else {
		if (COMPRESSED && worker) worker.postMessage({ [key]: toJson(value) })
		else localStorage.setItem(key, toJson(value))
	}
}

// export const Storage = IStorage({ getItem, setItem })
export const Storage = { getData, setData }
