import * as React from 'react'
import { NavLink } from 'react-router-dom'

import { styled, alpha } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import InputBase from '@mui/material/InputBase'
// import Badge from '@mui/material/Badge'
// import MenuItem from '@mui/material/MenuItem'
// import Menu from '@mui/material/Menu'
// import MenuIcon from '@mui/icons-material/Menu'
// import SearchIcon from '@mui/icons-material/Search'
// import AccountCircle from '@mui/icons-material/AccountCircle'
// import MailIcon from '@mui/icons-material/Mail'
// import NotificationsIcon from '@mui/icons-material/Notifications'
// import MoreIcon from '@mui/icons-material/MoreVert'

// const Search = styled('div')(({ theme }) => ({
// 	position: 'relative',
// 	borderRadius: theme.shape.borderRadius,
// 	backgroundColor: alpha(theme.palette.common.white, 0.15),
// 	'&:hover': {
// 		backgroundColor: alpha(theme.palette.common.white, 0.25),
// 	},
// 	marginRight: theme.spacing(2),
// 	marginLeft: 0,
// 	width: '100%',
// 	[theme.breakpoints.up('sm')]: {
// 		marginLeft: theme.spacing(3),
// 		width: 'auto',
// 	},
// }))

// const SearchIconWrapper = styled('div')(({ theme }) => ({
// 	padding: theme.spacing(0, 2),
// 	height: '100%',
// 	position: 'absolute',
// 	pointerEvents: 'none',
// 	display: 'flex',
// 	alignItems: 'center',
// 	justifyContent: 'center',
// }))

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
// 	color: 'inherit',
// 	'& .MuiInputBase-input': {
// 		padding: theme.spacing(1, 1, 1, 0),
// 		// vertical padding + font size from searchIcon
// 		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
// 		transition: theme.transitions.create('width'),
// 		width: '100%',
// 		[theme.breakpoints.up('md')]: {
// 			width: '20ch',
// 		},
// 	},
// }))

type AppBarProps = {
	pages: any[]
}

export default function PrimarySearchAppBar({ pages }: AppBarProps) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
		React.useState<null | HTMLElement>(null)

	const isMenuOpen = Boolean(anchorEl)
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
		handleMobileMenuClose()
	}

	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setMobileMoreAnchorEl(event.currentTarget)
	}

	const menuId = 'primary-search-account-menu'
	const mobileMenuId = 'primary-search-account-menu-mobile'

	return (
		// убрал sx={{ flexGrow: 1 }}, т. к. из-за него страницы занимали пол-экрана
		// <Box sx={{ flexGrow: 1 }}>
		<Box>
			<AppBar position='absolute'>
				<Toolbar>
					<Typography
						variant='h6'
						noWrap
						component='div'
						sx={{ display: 'block' }}
					>
						ЛК Клиента
					</Typography>
					<Box sx={{ flexGrow: 1 }} />
					<Box sx={{ display: { sm: 'flex', md: 'none' } }}>
						{pages.map(page => (
							<IconButton
								key={page.name}
								component={NavLink}
								to={page.path}
								size='large'
								color='inherit'
							>
								{page.icon}
							</IconButton>
						))}
						{/* <IconButton size='large' color='inherit'>
							<Badge badgeContent={null} color='error'>
							<MailIcon />
							</Badge>
						</IconButton>
						<IconButton size='large' color='inherit'>
							<Badge badgeContent={17} color='error'>
							<NotificationsIcon />
							</Badge>
						</IconButton>
						<IconButton
							size='large'
							edge='end'
							aria-label='account of current user'
							aria-controls={menuId}
							aria-haspopup='true'
							onClick={handleProfileMenuOpen}
							color='inherit'>
							<AccountCircle />
						</IconButton> */}
					</Box>
				</Toolbar>
			</AppBar>
		</Box>
	)
}
