import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	Box,
	Button,
	Container,
	Paper,
	TextField,
	Typography,
} from '@mui/material'

import { useAuth } from '@client/app/src/context/auth/AuthContext'

function LoginPage() {
	const navigate = useNavigate()
	const [name, setName] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const { login, isLoading, isAuthenticated, authError } = useAuth()

	useEffect(() => {
		if (isAuthenticated) navigate('/profile')
	}, [isAuthenticated, navigate])

	function handleSubmit(e) {
		e.preventDefault()
		login({ name, password })
	}

	return (
		<Container
			component='main'
			maxWidth='xs'
			sx={{ mt: 1, mb: 1 }}
			style={{ height: '100%' }}
		>
			<Paper>
				<Typography component='h1' variant='h5'>
					Введите имя пользователя и пароль
				</Typography>
				<Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						label='Имя пользователя'
						name='name'
						autoFocus
						value={name}
						onChange={event => setName(event.target.value)}
					/>
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						name='password'
						label='Пароль'
						type='password'
						autoComplete='current-password'
						value={password}
						onChange={event => setPassword(event.target.value)}
					/>
					{/* <FormControlLabel
					control={<Checkbox value="remember" color="primary" />}
					label="Remember me"
				/> */}
					<Button type='submit' fullWidth variant='contained' color='primary'>
						Войти
					</Button>
				</Box>
			</Paper>

			{/* <Message
				open={openSnackbar}
				close={handleCloseSnackbar}
				classes={classes}
				messageText={`Логин или пароль неправильные`}
			/> */}
		</Container>
	)
}

export default LoginPage
