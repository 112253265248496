export type getDialogChangedFieldParams = (
	name: string,
	value: string | Date | boolean,
	type?: string
) => { [key: string]: string | Date | boolean | number } | undefined

export function getDialogChangedField(
	name: string,
	value: string | Date | boolean,
	type?: string
): { [key: string]: string | Date | boolean | number } | undefined {
	let convertedValue
	if (type === 'date') convertedValue = value as Date
	else if (type === 'integer') {
		const number = Number(value)
		if (isNaN(number)) return

		convertedValue = Math.trunc(number)
	} else if (type === 'float') {
		const number = Number(value)
		if (isNaN(number)) return

		convertedValue = Number(value)
	} else if (type === 'string') convertedValue = String(value)
	else convertedValue = value

	if (
		convertedValue !== undefined ||
		(type && ['integer', 'float'].includes(type) && !isNaN(convertedValue))
	)
		return { [name]: convertedValue }
}

export type onDialogFieldChangeFn = (
	name: string,
	value: string | Date | boolean,
	type?: string
) => void
