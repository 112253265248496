import AccountCircle from '@mui/icons-material/AccountCircle'
import Calculate from '@mui/icons-material/Calculate'
import ArticleIcon from '@mui/icons-material/Article'
import AdfScannerIcon from '@mui/icons-material/AdfScanner'
import RouteIcon from '@mui/icons-material/Route'
import AddIcon from '@mui/icons-material/Add'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'

import PreviewIcon from '@mui/icons-material/Preview'

export const ProfileIcon = AccountCircle
export const CalculateIcon = Calculate
export const OrdersIcon = ArticleIcon
export const DocsIcon = AdfScannerIcon
export const OrderTrackingIcon = RouteIcon
export const NewOrderIcon = AddIcon

export const AddLineIcon = AddIcon
export const CopyLineIcon = ContentCopyIcon
export const RemoveLineIcon = RemoveCircleOutlineIcon

export { PreviewIcon }
