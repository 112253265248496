import InvoiceNumber from './InvoiceNumber'
import OrderNumber from './OrderNumber'
import { IOrderTrackingInfo } from '@client/app/typings/types'
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material'
import React, { FC } from 'react'

type Props = { infoArray: IOrderTrackingInfo[] }

const OrderTrackingInfo: FC<Props> = ({ infoArray }) => {
	return (
		<Box sx={{ py: 1 }}>
			{infoArray.map(info => {
				console.log(123)

				return info.type === 'notFound' ? (
					<span key='notFound'>
						по {info.orderNumber.length === 1 ? 'заказу' : 'заказам'}{' '}
						<strong>{info.orderNumber.join(', ')}</strong> информация не найдена
					</span>
				) : (
					<Paper key={info.uid} sx={{ my: 1 }}>
						<Box p={1}>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}
							>
								<Typography variant='h5'>
									Заказ <OrderNumber number={info.orderNumber} />
								</Typography>
								{/* </Box>
							<Box sx={{ display: 'flex', flexDirection: 'row' }}> */}
								<Typography variant='h6'>
									накладная <InvoiceNumber number={info.invoiceNumber} />
								</Typography>

								{info.customerOrderNumber ? (
									<Typography variant='body1'>
										ссылка {info.customerOrderNumber}
									</Typography>
								) : null}
							</Box>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}
							>
								<Box>
									<strong>
										{info.from} {'->'} {info.to}
									</strong>
								</Box>
								<Box>
									<span>
										Статус: <strong>{info.tracking[0].state}</strong>
									</span>
								</Box>
							</Box>

							{/* // 	<Box
					// 		sx={{
					// 			p: 2,
					// 		}}
					// 	>
					// 		<Box sx={{ display: 'flex', flexDirection: 'row' }}>
					// 			<OrderNumber number={info.orderNumber} />
					// 			<InvoiceNumber number={info.invoiceNumber} />
					// 			<span> {info.customerOrderNumber}</span>
					// 		</Box>
					// 		<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					// 			<strong>
					// 				{info.from} {'->'} {info.to}
					// 			</strong>
					// 			<span>
					// 				Статус: <strong>{info.tracking[0].state}</strong>
					// 			</span>
					// 		</Box>
					// 		<View>
					// 			{info.tracking.map(trackingLine => (
						// 				<div key={trackingLine.lineNumber}>
						// 					<Text>
					// 						[{trackingLine.date}] {trackingLine.state}
					// 					</Text>
					// 					<Text>{trackingLine.city}</Text>
					// 				</div>
					// 			))}
					// 		</View>
					// 	</Box> */}
							<TableContainer>
								<Table size='small'>
									<TableHead>
										<TableRow>
											<TableCell align='center'>№</TableCell>
											<TableCell align='center'>Дата/время</TableCell>
											<TableCell align='center'>Состояние</TableCell>
											<TableCell align='center'>Доп.информация</TableCell>
											<TableCell align='center'>Место нахождения</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{info.tracking.map(trackingLine => (
											<TableRow key={trackingLine.lineNumber}>
												<TableCell align='center'>
													{trackingLine.lineNumber}
												</TableCell>
												<TableCell align='center'>
													{trackingLine.date}
												</TableCell>
												<TableCell align='left'>{trackingLine.state}</TableCell>
												<TableCell align='center'>
													{trackingLine.city}
												</TableCell>
												<TableCell align='center'>
													{trackingLine.additionalInfo}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</Paper>
				)
			})}
		</Box>
	)
}

export default OrderTrackingInfo
