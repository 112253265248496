/**
 * @description   Types is just for constant that for dispatching our action in order to reducing a Misspelling
 */

export const START_AUTHENTICATION = 'START_AUTHENTICATION'
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED'
export const USER_NOT_AUTHENTICATED = 'USER_NOT_AUTHENTICATED'
export const AUTH_FAIL = 'AUTH_FAIL'
export const USER_FOUND = 'USER_FOUND'
export const USER_NOT_FOUND = 'USER_NOT_FOUND'
export const SET_PROFILE = 'SET_PROFILE'
export const APP_IS_INITIALIZED = 'APP_IS_INITIALIZED'
// export const CREATE_TODO = 'CREATE_TODO'
// export const DELETE_TODO = 'DELETE_TODO'
