import React, {
	ChangeEvent,
	ChangeEventHandler,
	SyntheticEvent,
	useState,
} from 'react'
import {
	Autocomplete,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
} from '@mui/material'

import SelectFromListModal from './SelectFromListModal'
import SelectFromListModalControlledSearch from './SelectFromListModalControlledSearch'

type Props = {
	label: string
	items: any[]
	selectedValue: any
	selectedValueRepresentation: string
	setSelectedValue: (any) => void
	itemLabelKey?: string
	onFilterChange: (searchString: string) => void
}

function SelectFromListControlledSearch({
	label,
	selectedValue,
	setSelectedValue,
	selectedValueRepresentation,
	items,
	itemLabelKey = 'name',
	onFilterChange,
}: Props): JSX.Element {
	const [modalSelectionWindowVisibility, setModalSelectionWindowVisibility] =
		useState(false)

	function showModalSelectionWindow(e) {
		return setModalSelectionWindowVisibility(true)
	}

	function handleChange(event: SyntheticEvent, value) {
		// setSelectedValue(event.target.value)
		setSelectedValue(value)
	}

	function handleTextFieldChange(
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	): void {
		const { name, value } = event.target
		setSelectedValue(value)
	}

	function handleCloseModalSelectionWindow(_selectedValue?: any) {
		if (_selectedValue) setSelectedValue(_selectedValue)
		setModalSelectionWindowVisibility(false)
	}

	return (
		<>
			{/* <FormControl fullWidth sx={{ display: { xs: 'none', sm: 'flex' } }}>
				<Autocomplete
					options={items}
					getOptionLabel={option =>
						itemLabelKey ? (option as any)[itemLabelKey] : option
					}
					renderInput={params => {
						return (
							<TextField
								{...params}
								// onChange={event => handleTextFieldChange(event)}
								label={label}
								variant='standard'
								// value={selectedValueRepresentation}
							/>
						)
					}}
					value={selectedValue}
					inputValue={selectedValueRepresentation}
					onChange={handleChange}
					// inputValue={selectedValueRepresentation}
					// freeSolo
				/>
			</FormControl> */}
			<Button
				variant='outlined'
				fullWidth
				sx={{ textTransform: 'none' }} // , display: { xs: 'flex', sm: 'none' }
				onClick={showModalSelectionWindow}
			>
				{selectedValueRepresentation
					? selectedValueRepresentation
					: `нажмите чтобы выбрать ${label}`}
			</Button>
			<SelectFromListModalControlledSearch
				visible={modalSelectionWindowVisibility}
				close={handleCloseModalSelectionWindow}
				title={label}
				items={items}
				itemLabelKey={itemLabelKey}
				onFilterChange={onFilterChange}
			/>
		</>
	)
}

export default SelectFromListControlledSearch
