import {
	ProfileIcon,
	CalculateIcon,
	OrdersIcon,
	OrderTrackingIcon,
	NewOrderIcon,
} from '../icons/Icons'
import Layout from '../сomponents/Layout'
import VirtualizedListLayout from '../сomponents/VirtualizedListLayout'
import CalculatePage from './CalculatePage'
import LoginPage from './LoginPage'
import NewOrderPage from './NewOrderPage'
import OrderTrackingPage from './OrderTrackingPage'
import OrdersPage from './OrdersPage'
// import DocPage from './DocPage'
// import LoginPage from './LoginPage'
import ProfilePage from './ProfilePage'
import React from 'react'
import { Routes, Route, Link, Navigate } from 'react-router-dom'

export const pages = [
	// {
	// 	name: 'Profile',
	// 	title: 'Профиль',
	// 	path: '/profile',
	// 	component: ProfilePage,
	// 	element: (
	// 		<Layout>
	// 			<ProfilePage />
	// 		</Layout>
	// 	),
	// 	icon: <ProfileIcon />,
	// },
	{
		name: 'OrderTracking',
		title: 'Отслеживание',
		path: '/order-tracking',
		component: OrderTrackingPage,
		element: (
			<Layout>
				<OrderTrackingPage />
			</Layout>
		),
		icon: <OrderTrackingIcon />,
	},
	{
		name: 'Calculate',
		title: 'Калькулятор',
		path: '/calculate',
		component: CalculatePage,
		element: (
			<Layout>
				<CalculatePage />
			</Layout>
		),
		icon: <CalculateIcon />,
	},
	// {
	// 	name: 'Orders',
	// 	title: 'Заказы',
	// 	path: '/orders',
	// 	component: OrdersPage,
	// 	element: (
	// 		<VirtualizedListLayout>
	// 			<OrdersPage />
	// 		</VirtualizedListLayout>
	// 	),
	// 	icon: <OrdersIcon />,
	// },
	// {
	// 	name: 'NewOrder',
	// 	title: 'Новый заказ',
	// 	path: '/order/new',
	// 	component: NewOrderPage,
	// 	element: (
	// 		<Layout>
	// 			<NewOrderPage />
	// 		</Layout>
	// 	),
	// 	icon: <NewOrderIcon />,
	// },
]

export default function Pages() {
	return (
		<Routes>
			{/* <Route path='/' element={<VirtualizedListLayout />}> */}
			{/* <Route path='/'> */}
			{pages.map(page => (
				<Route key={page.name} path={page.path} element={page.element} />
			))}
			<Route
				key='OrderTracking_orders'
				path='/order-tracking/:ordersList'
				element={
					<Layout>
						<OrderTrackingPage />
					</Layout>
				}
			/>
			{/* <Route path='/:type/:id' element={<DocPage />} /> */}
			{/* <Route path='/pickups/order/:orderId' element={<PickupOrder />} />
				<Route
					path='/pickups/manifest/:manifestId/:orderId'
					element={<PickupOrder />}
				/>
				<Route
					path='/pickups/manifest/:manifestId'
					element={<PickupManifets />}
				/>
				<Route
					path='/deliveries/manifest/:manifestId/:orderId'
					element={<DeliveryOrder />}
				/>
				<Route
					path='/deliveries/manifest/:manifestId'
					element={<DeliveryManifest />}
				/>
				<Route path='/deliveries/order/:orderId' element={<DeliveryOrder />} />
				<Route
					path='/completed/manifest/:manifestId'
					element={<CompletedManifest />}
				/>
				<Route path='/completed/order/:orderId' element={<CompletedOrder />} /> */}
			{/* </Route> */}
			<Route path='login' element={<LoginPage />} />
			<Route path='*' element={<Navigate to='order-tracking' />} />
		</Routes>
	)
}
