import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Container from '@mui/material/Container'

import Calculate from '../сomponents/Calculate'
import { useAuth } from '@client/app/src/context/auth/AuthContext'
import { ErrorWithStatus, localityRecord } from '@client/app/typings/types'
import { getServerData } from '@client/app/src/api'

function setLocalityRepresentation(locality: localityRecord): string {
	const localityParts = [locality.locality]
	if (locality.street.trim()) localityParts.push(locality.street.trim())

	if (locality.houseNumber.trim())
		localityParts.push(locality.houseNumber.trim())

	return (
		localityParts.join(', ') +
		(locality.region !== locality.locality ? ` (${locality.region})` : '') +
		` индекс ${locality.postIndex}`
	)
}

const CalculatePage = () => {
	const { isInitialized, isAuthenticated, setUserNotAuthenticated } = useAuth()
	const navigate = useNavigate()
	const [localities, setLocalities] = useState<localityRecord[]>([])

	useEffect(() => {
		if (!isInitialized) return
		// if (!isAuthenticated) navigate('/login')
	}, [isInitialized, isAuthenticated, navigate])

	useEffect(() => {
		getServerData({}, 'addresses')
			.then(_localities => {
				setLocalities(
					_localities.sort(function (a, b) {
						if (a.locality > b.locality) return 1
						if (a.locality < b.locality) return -1
						return 0
					})
					// .map(locality => ({
					// 	locality: setLocalityRepresentation(locality),
					// 	uid: locality.uid,
					// }))
				)
			})
			.catch((error: ErrorWithStatus) => {
				if (error?.status === 401) setUserNotAuthenticated()
				console.error(error.message, error?.status)
			})
	}, [])

	return (
		<Container
			sx={{
				flexGrow: 1,
				overflow: 'auto',
			}}
		>
			<Calculate localities={localities} />
		</Container>
	)
}

export default CalculatePage
