import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import { localityRecord } from '@client/app/typings/types'
import { useSelectLocalityModal } from './useSelectLocalityModal'
import { ListItemButton } from '@mui/material'
import { ICustomer } from '@client/app/typings/new-order.types'

type Props = {
	visible: boolean
	close: (selectedCustomer?: ICustomer) => void
	title: string
	items: any[]
	itemLabelKey?: string
	onFilterChange: (searchString: string) => void
}

function SelectFromListModalControlledSearch({
	visible,
	close,
	title,
	items,
	itemLabelKey = 'name',
	onFilterChange,
}: Props): JSX.Element {
	const textInputRef = useRef(null)
	const [searchString, setSearchString] = useState<string>('')
	const [filteredItems, setFilteredItems] = useState<ICustomer[]>([])

	useEffect(() => {
		if (!visible) return

		setFilteredItems(items)
	}, [visible])

	function handleClose(event) {
		close(undefined)
	}

	function onTextFieldChange(event) {
		const _searchString = event.target.value
		setSearchString(_searchString)
		onFilterChange(_searchString)
	}

	function handleSelectItem(item) {
		close(item)
	}

	return (
		<Dialog
			open={visible}
			onClose={handleClose}
			scroll='paper'
			PaperProps={{ style: { height: '100%' } }}
		>
			{/* <DialogTitle id='scroll-dialog-title'>{title}</DialogTitle> */}
			<DialogContent>
				<TextField
					autoFocus
					margin='dense'
					label={title}
					type='text'
					fullWidth
					variant='standard'
					value={searchString}
					onChange={onTextFieldChange}
					ref={textInputRef}
				/>
				<List dense>
					{items.map(item => (
						<ListItem key={item.uid}>
							<ListItemButton onClick={() => handleSelectItem(item)}>
								<ListItemText primary={item.label} secondary={item.secondary} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</DialogContent>
		</Dialog>
	)
}

export default SelectFromListModalControlledSearch
