import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { ICalculateResult } from '@client/app/typings/calculate.types'
import FromToIcons from './FromToIcons'

type Props = { items: ICalculateResult[] }

function CalculateResults({ items }: Props) {
	return (
		<List>
			{items.map(item => (
				<ListItem
					key={
						item.transportationType.name +
						item.pickupVariant +
						item.deliveryVariant
					}
				>
					<FromToIcons item={item} />
					<ListItemText primary={`${item.transportationType.name}`} />
					<ListItemText
						primary={
							item.result
								? item.result
								: `срок ${item.shippingTimeMin} - ${item.shippingTimeMax} сут, ${item.sum} р.`
						}
					/>
				</ListItem>
			))}
		</List>
	)
}

export default CalculateResults
