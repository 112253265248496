import { Platform } from 'react-native'

export let API_URL
try {
	if (process.env.NODE_ENV === 'development') {
		console.log('current os', Platform.OS)

		if (Platform.OS === 'web') API_URL = ''
		else API_URL = 'http://192.168.1.105:3000'
	}
	// API_URL = 'http://partner.aeroline.su'
	else API_URL = '' // `${window.location.hostname}`
	// ВРЕМЕННО !!!
	// else API_URL = '/new' // `${window.location.hostname}`
} catch (error) {
	console.error(error)
}
