import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ruLocale from 'date-fns/locale/ru'

import AuthState from '@client/app/src/context/auth/AuthState'
import Pages, { pages } from './pages/Pages'
import { LocalizationProvider } from '@mui/x-date-pickers'

function ErrorFallback({ error, resetErrorBoundary }) {
	return (
		<div role='alert'>
			<p>Something went wrong:</p>
			<pre>{error.message}</pre>
			<button onClick={resetErrorBoundary}>Try again</button>
		</div>
	)
}

function App() {
	return (
		// <ErrorBoundary
		// 	FallbackComponent={ErrorFallback}
		// 	onReset={() => {
		// 		console.error('some error')
		// 	}}
		// >
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
			<AuthState>
				<BrowserRouter>
					<Pages />
				</BrowserRouter>
			</AuthState>
		</LocalizationProvider>
		// </ErrorBoundary>
	)
}

export default App
